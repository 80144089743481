<template>
  <div v-if="step == 1">
    <div id="container">
      <custom-input
        label="Password"
        :value="password"
        type="text"
        @input="(val) => (password = val)"
        @change="checkPassword"
      />
      <custom-input
        label="Conferma password"
        :value="password2"
        type="text"
        @input="(val) => (password2 = val)"
        @change="checkPassword"
      />
      <div id="errorMsg" v-if="checkPassword" v-html='$gettext(errorMsg)'></div>
      <div id="buttons" v-if="!loading">
        <button :disabled="!sendOk" @click="changePasword">{{ $gettext('Invia richiesta') }}</button>
      </div>
      <div id="loading" v-if="loading">{{ $gettext('Richiesta in corso...') }}</div>
    </div>
  </div>
  <div v-if="step == 2">
    <h1>{{ $gettext('La tua password è stata aggiornata.') }}</h1>
    <div id="buttons" v-if="!loading">
      <button @click="profilo">{{ $gettext('Vai al tuo profilo') }}</button>
    </div>
  </div>
</template>

<script>
import customInput from '@/components/shared/custom-input';
export default {
  name: 'changePassword',
  components: { customInput },
  data() {
    return {
      password: '',
      password2: '',
      errorMsg: '',
      loading: false,
      step: 1,
    };
  },
  methods: {
    changePasword() {
      const token = this.$store.getters['auth/getToken'];
      this.loading = true;
      this.errorMsg = '';
      this.axios
        .post(
          this.api.changePassword,
          {
            params: {
              password: this.password,
            },
          },
          {
            headers: {
              Authorization: 'Bearer ' + token,
            },
          },
        )
        .then((response) => {
          this.loading = false;
          this.step = 2;
        })
        .catch((error) => {
          console.log(error);
          this.loading = false;
          alert(
            $gettext("In questo momento non è stato possibile effettuare l'operazione di cambio password. Prova tra qualche minuto"),
          );
        });
    },
    profilo() {
      this.$router.push({ name: 'Profile' });
    },
    checkPassword() {
      if (this.password.trim() != this.password2.trim()) {
        this.errorMsg = 'Le due password non coincidono';
        return true;
      }
      this.errorMsg = '';
      return false;
    },
  },
  computed: {
    sendOk() {
      if (
        !this.checkPassword() &&
        this.password.trim() != '' &&
        this.password2.trim() != '' &&
        this.password.trim() == this.password2.trim() &&
        this.password.trim().length >= 8 &&
        this.password2.trim().length >= 8
      )
        return true;
      return false;
    },
  },
};
</script>

<style scoped>
#buttons {
  margin-top: 10px;
}
#loading {
  margin-top: 10px;
  font-style: italic;
  font-weight: 900;
}

#errorMsg {
  margin-top: 10px;
  color: var(--var-primary-color);
  font-weight: 600;
}
</style>
